import { AiApi } from "@evercam/shared/api/aiApi"
import { TimelineEvent } from "@evercam/ui"
import {
  GateReportVehicleType,
  ProjectExid,
  TimelineDateInterval,
  TimelineProviderRequestParams,
} from "@evercam/shared/types"
import { TimelineDataProvider } from "./timelineDataProvider"

export class TimelineGateReportProvider extends TimelineDataProvider {
  private static eventsPromise: Promise<Array<TimelineEvent>> | null = null
  private static countsPromise: Promise<Array<TimelineEvent>> | null = null
  readonly projectExid: ProjectExid
  readonly vehicleTypeFilterFn: (v: GateReportVehicleType) => boolean

  constructor(params: {
    timezone: string
    projectExid: ProjectExid
    vehicleTypeFilterFn: (v: GateReportVehicleType) => boolean
  }) {
    super(params.timezone)
    this.projectExid = params.projectExid
    this.vehicleTypeFilterFn = params.vehicleTypeFilterFn
  }

  async fetchEvents(
    params: TimelineDateInterval
  ): Promise<Array<TimelineEvent>> {
    if (!TimelineGateReportProvider.eventsPromise) {
      TimelineGateReportProvider.eventsPromise = this.doFetchEvents(
        params
      ).finally(() => {
        TimelineGateReportProvider.eventsPromise = null
      })
    }

    return TimelineGateReportProvider.eventsPromise.then((events) =>
      events.filter((e) => this.vehicleTypeFilterFn(e.truckType))
    )
  }

  async fetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>> {
    if (!TimelineGateReportProvider.countsPromise) {
      TimelineGateReportProvider.countsPromise = this.doFetchCounts(
        params
      ).finally(() => {
        TimelineGateReportProvider.countsPromise = null
      })
    }

    return TimelineGateReportProvider.countsPromise.then((counts) => {
      return counts.map(({ date, counts }) => ({
        timestamp: this.formatTimestamp(date),
        count: Object.entries(counts).reduce((acc, [vehicleType, count]) => {
          if (this.vehicleTypeFilterFn(vehicleType as GateReportVehicleType)) {
            return acc + Number(count)
          } else {
            return acc
          }
        }, 0),
      }))
    })
  }

  private async doFetchEvents(
    params: TimelineDateInterval
  ): Promise<Array<TimelineEvent>> {
    const { items } = await AiApi.gateReport.getAllEvents(this.projectExid, {
      fromDate: params.fromDate,
      toDate: params.toDate,
      limit: 6000,
    })

    return items.map((e) => ({
      ...e,
      timestamp: this.formatTimestamp(e.eventTime),
    }))
  }

  private async doFetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>> {
    return await AiApi.gateReport.getDetailedEventCounts(this.projectExid, {
      startDate: params.fromDate,
      endDate: params.toDate,
      precision: params.precision,
    })
  }
}
