
import { AnalyticsEvent, ImageQuality } from "@evercam/shared/types"
import PlayPauseButton from "@evercam/shared/components/imagePlayer/PlayPauseButton"
import { exitFullScreen, makeFullScreen } from "@evercam/ui"

export default {
  name: "PlayerActions",
  components: {
    PlayPauseButton,
  },
  props: {
    frames: {
      type: Array,
      default: () => [],
    },
    frameIndex: {
      type: Number,
      default: 0,
    },
    isLastFrame: {
      type: Boolean,
      default: true,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    timePerFrame: {
      type: Number,
      default: 250,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: Object,
      default: () => ({
        index: "",
        label: "",
      }),
    },
    hasLive: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    disablePlayButton: {
      type: Boolean,
      default: false,
    },
    initialSnapshotQuality: {
      type: [String, Number],
      default: ImageQuality.auto,
    },
    showSnapshotQuality: {
      type: Boolean,
      default: false,
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    hasEdgeVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recordingType: "jpegs",
      allowedSpeeds: [0.25, 0.5, 0.75, 1, 1.5, 2, 2.5],
      selectedSpeedIndex: 3,
      allowedQualities: Object.values(ImageQuality),
      selectedQualityIndex: 4,
      isFullscreen: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$config.public.appName === "admin"
    },
    isEdgeVideoEnabled() {
      return this.recordingType === "video"
    },
    switchOptions() {
      return [
        {
          title: "VIDEO",
          value: "video",
        },
        {
          title: "JPEGS",
          value: "jpegs",
        },
      ]
    },
  },
  watch: {
    isEdgeVideoEnabled(value) {
      this.$emit("toggle-video-mode", value)
    },
    isVideo: {
      handler(value) {
        this.recordingType = value ? "video" : "jpegs"
      },
      immediate: true,
    },
    recordingType(value, oldValue) {
      if (value !== oldValue) {
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.playerSwitchMode,
          params: { mode: value },
        })
      }
    },
  },
  mounted() {
    this.initPlayerSettings()
  },
  methods: {
    initPlayerSettings() {
      if (!this.isAdmin) {
        return
      }
      const speed = window.localStorage.getItem("snapshotsPlayerSpeed")
      const quality = window.localStorage.getItem("snapshotsPlayerQuality")
      this.selectedQualityIndex = this.allowedQualities.indexOf(
        quality ?? this.initialSnapshotQuality
      )
      this.selectedSpeedIndex = speed
        ? this.allowedSpeeds.indexOf(Number.parseFloat(speed))
        : 3
      this.updateTimePerFrame()
      this.updateQuality()
    },
    updateFrameIndex(amount) {
      if (amount > 0) {
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.playerJumptoNextTicks,
          params: { ticks: amount },
        })
      } else {
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.playerJumptoPreviousTicks,
          params: { ticks: amount },
        })
      }
      let newIndex = this.frameIndex + amount
      if (newIndex >= this.frames.length) {
        newIndex = this.frames.length - 1
      }

      if (newIndex < 0) {
        newIndex = 0
      }

      if (newIndex >= 0) {
        this.$emit("frame-index-change", {
          frameIndex: newIndex,
        })
      }
    },
    updateTimePerFrame() {
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.playerSelectSpeed,
        params: { speed: this.allowedSpeeds[this.selectedSpeedIndex] },
      })
      this.$emit("change", {
        timePerFrameValue: 250 / this.allowedSpeeds[this.selectedSpeedIndex],
      })
      if (this.isAdmin) {
        window.localStorage.setItem(
          "snapshotsPlayerSpeed",
          this.allowedSpeeds[this.selectedSpeedIndex]
        )
      }
    },
    updateQuality() {
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.playerSelectQuality,
        params: { quality: this.allowedQualities[this.selectedQualityIndex] },
      })
      this.$emit(
        "snapshots-quality",
        this.allowedQualities[this.selectedQualityIndex]
      )
      if (this.isAdmin) {
        window.localStorage.setItem(
          "snapshotsPlayerQuality",
          this.allowedQualities[this.selectedQualityIndex]
        )
      }
    },
    toggleFullscreen() {
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.playerToggleFullscreen,
        params: { active: !this.isFullscreen },
      })
      if (this.$device.isIos) {
        this.$emit("toggle-ios-fullscreen")

        return
      }
      if (!this.isFullscreen) {
        makeFullScreen(this.$globalRefs.playerWrapper).then(() => {
          this.isFullscreen = true
        })
      } else {
        exitFullScreen().then(() => {
          this.isFullscreen = false
        })
      }
      this.$root.$emit("image-player-fullscreen", this.isFullscreen)
      this.$emit("change", { isFullscreen: !this.isFullscreen })
    },
    togglePlaying() {
      if (this.isLive && this.frames.length > 0) {
        this.$emit("disable-live-view")
      }
      if (this.isPlaying) {
        this.$emit("pause")
      } else {
        this.$emit("play")
      }
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.playerTogglePlayback,
        params: { active: !this.isPlaying },
      })
    },
    enableLive() {
      this.$emit("enable-live-view")
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.playerEnableLive,
      })
    },
  },
}
